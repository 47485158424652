body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.links{
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;

}

.link{
    color: #333;
    font-weight: 500;
    text-decoration: none;
    text-transform: uppercase;
    padding: 5px 15px;
}

.link.active{
    color: #000;
    text-decoration: underline;
}

.textAlignCenter {
  text-align: center;
}

.Toastify__toast-container {
  font-size: 13px;
}

.Toastify__progress-bar--default {
  background: #54b952 !important;
}

.Toastify__close-button {
  background: none !important;
  border: none !important;
}
