// @media all and (min-width: 567px) {
  .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }

  .loginWrapper {
    float: left;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(45deg, #4878c6 15.38%, #5585d3 15.38%, #5585d3 50%, #4878c6 50%, #4878c6 65.38%, #5585d3 65.38%, #5585d3 100%);
    background-size: 14.14px 14.14px;
    float: left;
    width: 100%;
    height: 100vh;
  }

  .loginContainer {
    padding: 20px 40px;
    margin: auto;
    width: 550px;

    img {
      padding: 30px;
    }

    h1 {
      font-size: 24px;
      color: #fff;
      margin-bottom: 30px;
    }

    .form {
      background: #fff;
      padding: 20px;
      float: left;
      border: 1px solid #5e879b;
      width: 100%;
    }

    label {
      float: left;
    }

    form {
      margin-top: 30px;
      float: left;
      width: 100%;
    }

    input[type=email], input[type=password] {
      border: 1px solid #878787;
      background: #f2f2f2;
      font-size: 14px;
      padding: 10px;
    }

    button {
      float: right;
      background: #58b857;
      width: 100%;
      color: #fff;
      border: 1px solid #387748;
      margin: 10px 0 10px;
      padding: 10px 0;
      &:hover {
        background: #387748;
      }
      &:disabled {
        background: #58b857;
        border: 1px solid #387748;
      }
    }

    .loginButton:disabled {
      background: #58b857;
    }
  }

  .vpLogo {
    max-width: 185px;
  }
// }


@media only screen and (max-width: 600px) {
  .loginContainer {
    width: 100%;
  }
}
