@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');

* {
  font-family: 'Montserrat', sans-serif;
  outline: none !important;
}

.App {
  text-align: center;
}

.btn-primary.focus, .btn-primary:focus {
  box-shadow: none !important;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background: #00688B;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #54b952;
}

input:focus + .slider {
  box-shadow: 0 0 1px #54b952;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.searchFormContainer input[type=text] {
  padding: 9px 10px 8px 9px;
  border: 1px solid #00557e;
  font-size: 13px;
}

.noPadding {
  padding: 0 !important;
}

table {
  a {
    color: #2980b9 !important;
    text-decoration: underline !important;
  }
  font-size: 13px;
}

.candidateResults .table td {
  padding: 0.3em 1em !important;
  vertical-align: middle;
}

h1 {
  font-size: 22px !important;
}
