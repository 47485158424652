.newShortlist {
  margin: 20px 0 20px -10px;
  font-weight: bold;
  background: #08577e;
  border: 1px solid #183c4c;
  font-size: 13px;
  &:hover {
    background: #183c4c;
    border: 1px solid #183c4c;
  }
}

.shortlistSide {
  h3 {
    font-size: 16px;
    text-transform: uppercase;
    float: left;
    width: 100%;
    text-align: left;
    padding-left: 10px;
    font-weight:bold;
  }
}

ul.shortlistItems {
  padding: 0 10px;
  width: 100%;
  font-size: 13px;
  text-align: left;
  li {
    list-style-type: none;
    margin-bottom: 5px;
  }
}

.noShortlists {
  font-size: 12px;
  padding: 0 5px;
}

.candidateShortlist {
  font-size: 13px;
  table td {
    vertical-align: middle;
  }
}

.blueButton {
  font-weight: bold;
  background: #08577e !important;
  border: 1px solid #183c4c !important;
  font-size: 11px;
}

.removeButton {
  font-size: 11px !important;
}

.shortlistTitle {
  background: #e1e1e1;
  padding: 10px 20px;
  margin-bottom: 10px;
  float: left;
  width: 100%;
}

.tabsWrapper .verticalTabsContent h1.shortlistTitle {
  float: left;
  width: auto;
  margin-bottom: 0;
  font-size: 16px !important;
}

.shortlistButtons {
  float: right;
  margin-top: 5px;
  margin-right: 10px;
  button {
    margin-left: 5px;
    font-size: 13px;
    padding-top: 4px;
  }
}

.floatRight {
  float: right;
}

.noMarginRight {
  margin-right: 0 !important;
}
